//@theme-color: #d5b15f;
@theme-color: #db3747;
//@theme-color: #ED2336;
@dark-color: #202323;


@body-color: #555;
@heading-color: #444;
@body-font: 'Lato', sans-serif;
@heading-font: 'Urbanist', sans-serif;
@secondary-font: 'Crete Round', serif;

.bg-color {
    background: @theme-color !important;
}

.dark .feature-box h3 {
    color: #fff;
}

#logo a {
    display: inline-block;
    text-align: center;
}

#primary-menu {
    ul {
        padding-right: 0 !important;
        margin-right: 0 !important;
        border-right: 0 !important;

        li.active a {
            color: @theme-color;
        }

        @media only screen and (min-width: 990px) and (max-width: 1150px) {
            a {
                font-size: 12px !important;
            }

            li.homepage-item {
                display: none;
            }
        }
    }
}

ul.files-collection li {
    list-style: none;
}

legend.col-form-label {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #555;
    margin-bottom: 10px;
    cursor: pointer;
}

@media (max-width: 575px) {
    #content {
        .content-wrap {
            padding: 40px 0;
        }
    }
}

.borderDashed {
    border: 1px dotted red !important;
}

ul.iconlist {
    list-style: none;
    margin-top: 15px;
}

.competence .gmap {
    position: relative;
    width: 100%;
    height: 450px;
}

.etudes {
    .etudes-list {
        a {
            text-align: center;

            div {
                height: 20vh;
                padding: 10px;
                background-color: rgba(0, 0, 0, .5);
                background-blend-mode: color;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                justify-content: center;
                display: flex;
                flex-direction: row;
                align-items: center;
                transition: all 400ms;

                &:hover {
                    background-color: rgba(0, 0, 0, .1);
                }

                h2 {
                    padding: 0;
                    margin: 0;
                    color: #fff;

                }
            }
        }
    }

    .gmap {
        width: 100%;
        height: 450px;
        @media only screen and (min-width: 768px) {
            height: 100%;
        }
    }
}

.gmap {
    z-index: 50;
}

#slider {
    .swiper-wrapper {
        height: 100%;
        width: 100%;

        .swiper-slide {
            height: 100%;
            width: 100%;

            .video-wrap {
                overflow: hidden;

                video {
                    height: 100%;
                    width: 177.77777778vh; /* 100 * 16 / 9 */
                    min-width: 100%;
                    min-height: 56.25vw; /* 100 * 9 / 16 */


                    position: absolute;
                    left: 50%; /* % of surrounding element */
                    top: 50%;
                    transform: translate(-50%, -50%); /* % of current element */
                }
            }
        }
    }
}

#page-title {
    h1 {
        width: 100%;
        text-align: center;
        line-height: 1.1;
        @media only screen and (min-width: 1000px) {
            width: 50%;
            text-align: left;
        }
    }

    .breadcrumb {
        position: initial !important;
        margin-top: 10px !important;
        text-align: center;
        justify-content: center;
        @media only screen and (min-width: 1000px) {
            position: absolute !important;
            text-align: left;
            margin-top: -10px !important;
            justify-content: initial;
        }
    }
}

body.homepage #slider {
    h1 {
        box-sizing: border-box;
        color: rgb(238, 238, 238);
        display: block;
        font-family: @heading-font;
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        margin-bottom: 10px;
        margin-top: 30px;
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
    }

    p {
        color: rgb(238, 238, 238);
        font-family: @heading-font;
        font-size: 64px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
        line-height: 64px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        text-align: center;
        text-shadow: rgba(0, 0, 0, 0.15) 1px 1px 1px;
    }
}

#footer {
    h4 {
        color: rgba(255, 255, 255, 0.25);
        font-weight: 700;
        font-family: "Lato", sans-serif;
        font-size: 14px;
        margin-bottom: 10px;
    }

    dt {
        padding-left: 10px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.25);
    }

    dd {
        padding-left: 10px;
        font-size: 13px;
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 5px;
        line-height: 15px;
    }
}

form label.required {
    &:after {
        color: #e32;
        content: ' *';
        display: inline;
    }
}

ol.breadcrumb {
    span {
        display: inline !important;
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    #slider,
    .swiper-container,
    .swiper-wrapper:not(.full-screen):not(.canvas-slider-grid),
    .swiper-wrapper:not(.full-screen):not(.canvas-slider-grid) .swiper-slide {
        height: 400px !important;

        .slider-caption p {
            display: block !important;
        }
    }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {

    #slider,
    .swiper-container,
    .swiper-wrapper:not(.full-screen):not(.canvas-slider-grid),
    .swiper-wrapper:not(.full-screen):not(.canvas-slider-grid) .swiper-slide {
        height: 300px !important;

        h1 {
            margin-top: 10px !important;
        }
    }
}

@media only screen and (max-width: 575px) {

    #slider,
    .swiper-container,
    .swiper-wrapper:not(.full-screen):not(.canvas-slider-grid),
    .swiper-wrapper:not(.full-screen):not(.canvas-slider-grid) .swiper-slide {
        height: 200px !important;

        h1 {
            margin-top: 10px !important;
        }
    }
}

.etude-image {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center center;
    margin-bottom: 20px;
}


.custom-file-input ~ .custom-file-label::after {
    content: "Parcourir" !important;
}

#header #primary-menu ul li.inversed a {
    background: @theme-color;
    color: #fff;

    &:hover {
        //background: @theme-color;
        color: @dark-color;
    }
}


@media (max-width: 576px) {
    .footerLast {
        margin-top: 20px;
        clear: both;
    }
}

//@import "~bootstrap/dist/css/bootstrap.css";
//@import "~bootstrap/dist/css/bootstrap-grid.css";

// Initialize
@import "canvas/variables.less";
@import "canvas/mixins.less";


// Core CSS
@import "canvas/typography.less";
@import "canvas/helpers.less";
@import "canvas/layouts.less";


// Content Blocks
@import "canvas/topbar.less";
@import "canvas/header.less";
@import "canvas/sliders.less";
@import "canvas/pagetitle.less";
@import "canvas/content.less";
//@import "canvas/portfolio.less";
//@import "canvas/blog.less";
//@import "canvas/shop.less";
//@import "canvas/events.less";


// Shortcodes
//@import "canvas/shortcodes/countdown.less";
@import "canvas/shortcodes/buttons.less";
//@import "canvas/shortcodes/promo.less";
@import "canvas/shortcodes/feature-box.less";
//@import "canvas/shortcodes/process-steps.less";
@import "canvas/shortcodes/alerts.less";
@import "canvas/shortcodes/styled-icons.less";
//@import "canvas/shortcodes/social-icons.less";
//@import "canvas/shortcodes/toggles-accordions.less";
//@import "canvas/shortcodes/tabs.less";
//@import "canvas/shortcodes/faqs.less";
//@import "canvas/shortcodes/clients.less";
//@import "canvas/shortcodes/testimonials.less";
//@import "canvas/shortcodes/team.less";
//@import "canvas/shortcodes/pricing.less";
//@import "canvas/shortcodes/counter-skills.less";
//@import "canvas/shortcodes/carousel.less";
//@import "canvas/shortcodes/overlay.less";
@import "canvas/shortcodes/headings.less";
@import "canvas/shortcodes/dividers.less";
//@import "canvas/shortcodes/youtubebg.less";
@import "canvas/shortcodes/misc.less";




// Footer
@import "canvas/footer.less";


// Widgets
//@import "canvas/widgets.less";


// Extras
@import "canvas/extras.less";
@import "canvas/responsive.less";
@import "canvas/dark.less";

@import "~@fortawesome/fontawesome-pro/less/fontawesome.less";
@import "~@fortawesome/fontawesome-pro/less/regular.less";
@import "~@fortawesome/fontawesome-pro/less/brands.less";
@import "~@fortawesome/fontawesome-pro/less/light.less";
@import "~@fortawesome/fontawesome-pro/less/solid.less";
@import "~@fortawesome/fontawesome-pro/less/v4-shims.less";

@import "~animsition/dist/css/animsition.css";
//@import "~flickity/dist/flickity.css";
@import "custom";